import React from 'react';
import PropTypes from 'prop-types';

import { marquee as MarqueePropType } from 'lib/CustomPropTypes';

import { Breaking } from './Breaking';
import { Embed } from './Embed';

function Marquee({ marquees }) {
  return (
    <>
      {marquees.map((marquee, index) => {
        const { marqueeType: type } = marquee;
        const key = `marquee-${index}`;
        // Embed marquee
        if (type === 'embed') {
          return (
            <Embed
              key={key}
              index={index}
              marquee={marquee}
            />
          );
        }
        // breakingNews, callOut, etc marquee
        if (type !== 'embed') {
          return (
            <Breaking
              key={key}
              marquee={marquee}
            />
          );
        }
        // Unsupported type?
        return null;
      })}
    </>
  );
}

Marquee.propTypes = {
  marquees: PropTypes.arrayOf(MarqueePropType).isRequired,
};

export { Marquee };
