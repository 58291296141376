import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import verticalLink from 'lib/verticalLink';

import VerticalLogo, { THEME as LOGO_THEME } from 'components/logos/Vertical';
import GlobalCitizenLogo from 'components/logos/GlobalCitizen';
import MSNBCLogo from 'components/logos/MSNBC';
import NewsLogo from 'components/logos/News';
import NoticiasLogo from 'components/logos/Noticias';
import { SelectLogo } from 'components/logos/Select';
import TodayLogo from 'components/logos/Today';

import { loadNavigation as loadNavigationAction } from 'redux/modules/layout';

const mapStateToProps = ({
  shared,
  layout,
}) => ({
  vertical: shared.vertical,
  navigation: layout.navigation,
});

const mapActionsToProps = (dispatch) => ({
  loadNavigation: (vertical) => dispatch(loadNavigationAction(vertical)),
});

class Fallback extends React.Component {
  static propTypes = {
    vertical: PropTypes.string.isRequired,
    loadNavigation: PropTypes.func.isRequired,
    navigation: PropTypes.shape({
      global: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          url: PropTypes.shape({
            primary: PropTypes.string,
          }),
        }),
      ),
      local: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          url: PropTypes.shape({
            primary: PropTypes.string,
          }),
        }),
      ),
    }),
  };

  static defaultProps = {
    navigation: {},
  };

  componentDidMount() {
    const { loadNavigation, vertical } = this.props;
    loadNavigation(vertical);
  }

  logo = () => {
    const { vertical } = this.props;
    const attribs = {
      vertical,
      color: LOGO_THEME.LIGHT,
    };
    switch (vertical) {
      case 'news':
        return <NewsLogo {...attribs} />;
      case 'noticias':
        return <NoticiasLogo {...attribs} />;
      case 'today':
        return <TodayLogo {...attribs} />;
      case 'msnbc':
        return <MSNBCLogo {...attribs} />;
      case 'globalcitizen':
        return <GlobalCitizenLogo {...attribs} />;
      case 'select':
        return <SelectLogo {...attribs} />;
      default:
        return <VerticalLogo {...attribs} />;
    }
  }

  // Format of the url property differs between APIs
  url = (url) => url.primary || url;

  global = () => {
    const { navigation: { global: items = [] } } = this.props;
    if (!items || !items.length) {
      return null;
    }
    return (
      <div className="bg-black ph5">
        <ul className="df items-center pv4">
          {items.map((item) => (
            <li key={item.text} className="o-60 mr5">
              <a href={this.url(item.url)}>
                {item.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  local = () => {
    const { navigation: { local: items = [] } } = this.props;
    if (!items || !items.length) {
      return null;
    }
    return (
      <div className="flex-1 mh-auto dn db-m">
        <ul className="db tc h-100 overflow-y-hidden">
          {items.map((item) => (
            <li key={item.text} className="dib mr7">
              <a href={this.url(item.url)}>
                {item.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    const { vertical } = this.props;
    return (
      <div
        ref={(el) => { this.container = el; }}
        className="layout-header__default founders-cond f5 ttu white"
        data-testid="service-header__default"
      >
        {this.global()}
        <nav className="layout-header__default-navbar df relative z-1">
          <div className="layout-header__default-logo flex-grow-0 df relative">
            <a href={`/${verticalLink(vertical)}`}>
              {this.logo()}
            </a>
          </div>
          {this.local()}
        </nav>
      </div>
    );
  }
}

export const HeaderFallback = connect(mapStateToProps, mapActionsToProps)(
  Fallback,
);
