import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const block = 'skip-link';

/**
 * Overlay for the "Skip to Content" button that appears when a user
 * tabs through the page
 * @returns {JSX.Element} React Component
 */
function SkipLinkOverlay() {
  const { t } = useTranslation();

  const navigateToMainContent = () => {
    const mainContent = document.querySelector('#header-end');
    if (mainContent) {
      mainContent.scrollIntoView();
      mainContent.focus();
    }
  };

  return (
    <div
      id="skip-link"
      className={classNames(`${block}__container`)}
    >
      <button
        className={classNames(`${block}__button`)}
        type="button"
        label={t('Skip to Content')?.toUpperCase()}
        onClick={navigateToMainContent}
        tabIndex={0}
      >
        {t('Skip to Content')?.toUpperCase()}
      </button>

    </div>
  );
}

export { SkipLinkOverlay };
