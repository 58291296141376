import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const THEME = {
  DARK: 'black',
  LIGHT: 'white',
};

class VerticalLogo extends Component {
  static propTypes = {
    vertical: PropTypes.string.isRequired,
    color: PropTypes.oneOf(Object.values(THEME)),
    active: PropTypes.bool,
    scrolled: PropTypes.bool,
  };

  static defaultProps = {
    color: THEME.DARK,
    active: false,
    scrolled: false,
  };

  peacock = () => (
    /* eslint-disable max-len */
    <svg className={styles.peacock} width="67" height="39" viewBox="578 62 67 39" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="evenodd">
        <path d="M579.074 90.58c1.717-4.494 6.472-6.08 10.697-3.37l18.29 12.754h-22.18c-4.62 0-8.252-4.23-6.8-9.385" />
        <path className={styles.feather} d="M579.074 90.58c1.717-4.494 6.472-6.08 10.697-3.37l18.29 12.754h-22.18c-4.62 0-8.252-4.23-6.8-9.385" />
        <path className={styles.feather} d="M597.563 75.863l9.442 21.613-19.478-13.35c-4.887-3.24-3.896-8.79-1.19-11.237 3.83-3.24 9.245-2.12 11.226 2.97" />
        <path className={styles.feather} d="M610.57 70.93l-3.433 24.19-9.772-23c-2.113-5.75 2.64-9.516 5.81-9.583 3.632-.396 8.188 2.38 7.396 8.394" />
        <path className={styles.feather} d="M625.494 72.103l-9.707 23.067-3.102-20.82c-.265-3.17 1.716-3.237 2.443-3.832-.133-.463-1.124-.463-1.124-.463h-1.848c-.198-5.42 4.028-7.8 7.462-7.535 3.697.132 7.856 4.428 5.876 9.583" />
        <path className={styles.feather} d="M635.338 84.167L615.86 97.584l9.177-21.348c2.775-6.742 9.245-5.42 11.556-2.842 2.51 2.51 3.235 7.6-1.255 10.773" />
        <path className={styles.feather} d="M637.448 99.9h-22.515l18.29-12.756c4.49-2.975 9.244-.266 10.498 3.635 1.327 4.36-1.65 9.12-6.27 9.12" />
      </g>
    </svg>
    /* eslint-enable max-len */
  );

  logo = () => {
    const { vertical } = this.props;
    return (
      <span className={`${styles.logo} icon icon-vertical-${vertical}`} />
    );
  }

  tagline = () => {
    const { vertical } = this.props;
    if (vertical === 'think') {
      return (
        <span
          className={`${styles.tagline} f3 f4-m lh-none db-print f4-print`}
          data-testid="vertical-logo__tagline"
        >
          Opinion, Analysis, Essays
        </span>
      );
    }

    return null;
  }

  render() {
    const { color, active, scrolled } = this.props;

    const tagline = this.tagline();

    const containerClasses = classNames(
      styles.verticalLogo,
      styles[color],
      {
        [styles.active]: active,
        [styles.scrolled]: scrolled,
        [styles.hasTagline]: tagline,
      },
    );

    return (
      <span className={containerClasses}>
        {this.peacock()}
        <span className={styles.vertical}>
          {this.logo()}
          {tagline}
        </span>
      </span>
    );
  }
}

export default VerticalLogo;
