import React from 'react';

import PropTypes from 'prop-types';

import WhiteLogo from './logo-white.svg';
import BlackLogo from './logo-black.svg';

import styles from './styles.module.scss';

class GlobalCitizenLogo extends React.Component {
  static propTypes = {
    color: PropTypes.string.isRequired,
    active: PropTypes.bool,
  };

  static defaultProps = {
    active: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      black: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { active } = this.props;

    if (!active && nextProps.active) {
      // Manual Animation for source switch.
      setTimeout(() => {
        this.setState({ black: true });
      }, 1000);
    } else if (active && !nextProps.active) {
      this.setState({ black: false });
    }
  }

  color() {
    const { color } = this.props;
    const { black } = this.state;

    if (black || color === 'black') {
      return BlackLogo;
    }

    return WhiteLogo;
  }

  render() {
    return (
      <span className={styles.logo}>
        <img alt="Global Citizen" src={this.color()} />
      </span>
    );
  }
}

export default GlobalCitizenLogo;
