import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import loadScript from 'lib/loadScript';
import { marquee as MarqueePropType } from 'lib/CustomPropTypes';

import styles from './styles.module.scss';

const elementId = 'embed-marquee';

function Embed({ index, marquee }) {
  React.useEffect(() => {
    loadScript('https://nodeassets.nbcnews.com/cdnassets/pym/1.3.2/pym.min.js')
      .then(() => {
        let url = get(marquee, ['embed', 'url'], '').trim();

        if (url && window.pym) {
          // Strip Pym query string params from url that might have been copied.
          url = url.replace(/&(childId|parentUrl)=[^&]+/g, '');
          // Pym adds iframe and establishes communication for controlling height.
          //* Each iframe needs to be created with a new pym Parent, otherwise subsequent iframes
          //* will overwrite the prior ones
          window[`_nnd-marquee-${index}`] = new window.pym.Parent(elementId, url);
        }
      });
  }, []);

  return (
    <div className={styles.marqueeEmbed} data-testid="marqueeEmbed">
      <div id={elementId} />
    </div>
  );
}

Embed.propTypes = {
  index: PropTypes.number.isRequired,
  marquee: MarqueePropType.isRequired,
};

export { Embed };
